import {
  CancelSubscriptionModel,
  ChangeSubscriptionModel,
  CreateSubscriptionsModel,
  GetAllSubscriptionsModel,
  GetUserSubscriptionModel,
} from '../models';

export namespace ClientPlansActions {
  export class List {
    static readonly type = '[ClientPlans] Get all subscription of user';
    constructor(public query: GetAllSubscriptionsModel.Request | null) {}
  }

  export class SubscribeNewUser {
    static readonly type = '[ClientPlans] Create Subscription new user';
    constructor(public body: CreateSubscriptionsModel.Request) {}
  }

  export class SubscribeUserLogged {
    static readonly type = '[ClientPlans] Create Subscription user logged';
    constructor(public body: CreateSubscriptionsModel.ReqUserLogged) {}
  }

  export class ChangeSubscription {
    static readonly type = '[ClientPlans] Change Subscription user logged';
    constructor(
      public body: ChangeSubscriptionModel.Request,
      public currentID: number | string
    ) {}
  }

  export class CancelSubscription {
    static readonly type = '[ClientPlans] Cancel Subscription';
    constructor(
      public body: CancelSubscriptionModel.Request,
      public id: number | string
    ) {}
  }

  export class DaysRemaining {
    static readonly type = '[ClientPlans] get days remaining plan';
    constructor(
      public id: number | string
    ) {}
  }

  export class ListUserSubs {
    static readonly type = '[ClientPlans] Get user subscription';
    constructor(public query: GetUserSubscriptionModel.Request | null) {}
  }

  export class Failure {
    static readonly type = '[ClientPlans] Load Error';
    constructor(public error: unknown) {}
  }
}
