/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';

import { AuthActions } from '@carwash-project/modules/data-access/auth';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import {
  ClientPlanModel,
  GetAllSubscriptionsModel,
  GetUserSubscriptionModel,
  UserVehiclesModel,
} from '../models';
import { ClientPlansService } from '../services/client-plans.service';
import { ClientPlansActions } from './client-plans.actions';
import { ErrorMessageModel } from '@carwash-project/modules/core';
import {
  StorageActions,
  StorageState,
} from '@carwash-project/modules/data-access/storage';
import { addMinutes } from 'date-fns';
import { toSignal } from '@angular/core/rxjs-interop';
import { Navigate } from '@ngxs/router-plugin';
import { TableData } from '@carwash-project/modules/ui';

const transformDataByTable = (data: ClientPlanModel.Subscription[]) => {
  return data.map((item) => ({
    ...item,
    plan_name: item.plan ? item.plan.name : '',
    plan_price: item.plan ? item.plan.price : '',
    plan_services: item.plan ? item.plan.services.join(', ') : '',
  }));
};

/** Models */
export const CLIENT_PLANS_STATE_TOKEN = new StateToken<ClientPlansStateModel>(
  'clientPlans'
);

export interface ClientPlansStateModel {
  subscriptions: GetAllSubscriptionsModel.Response | null;
  userSubs: GetUserSubscriptionModel.Response | null;
  subsID: number | null;
  daysRemaining: number | null;
  loading: boolean;
  error: unknown;
}

export const initialState: ClientPlansStateModel = {
  subscriptions: null,
  userSubs: null,
  subsID: null,
  daysRemaining: null,
  loading: false,
  error: null,
};

/** State */
@State<ClientPlansStateModel>({
  name: CLIENT_PLANS_STATE_TOKEN,
  defaults: initialState,
})
@Injectable()
export class ClientPlansState {
  private readonly store = inject(Store);
  private readonly waitTime = toSignal(
    this.store.select(StorageState.waitTime)
  );
  private readonly clientPlansService = inject(ClientPlansService);

  @Selector()
  public static subscriptions(state: ClientPlansStateModel) {
    return state.subscriptions;
  }

  @Selector()
  public static subscriptionsTable(state: ClientPlansStateModel) {
    return new TableData(
      transformDataByTable(state.subscriptions?.results ?? []),
      state.subscriptions?.totalCount
    );
  }

  @Selector()
  public static subsID(state: ClientPlansStateModel) {
    return state.subsID;
  }

  @Selector()
  public static userSubs(state: ClientPlansStateModel) {
    return state.userSubs;
  }

  @Selector()
  public static userSubsAddVehicles(state: ClientPlansStateModel) {
    return state.userSubs
      ? ({
          ...state.userSubs,
          results: state.userSubs.results.filter(
            (subs) => !subs.vehicles.length
          ),
        } as GetUserSubscriptionModel.Response)
      : null;
  }

  @Selector()
  public static daysRemaining(state: ClientPlansStateModel) {
    return state.daysRemaining;
  }

  @Selector()
  public static userSubsVehicles(
    state: ClientPlansStateModel
  ): UserVehiclesModel[] {
    return state.userSubs
      ? state.userSubs.results
          .map((subs) => ({
            ...subs,
            vehicles: subs.vehicles.map((vehicle) => ({
              ...vehicle,
              clientPlanName: subs.plan.name,
              canEdit: false,
            })),
          }))
          .map((item) => item.vehicles)
          .flat()
      : [];
  }

  @Selector()
  public static loading(state: ClientPlansStateModel) {
    return state.loading;
  }

  /** Action Handle Error */
  @Action(ClientPlansActions.Failure)
  public handleError(
    ctx: StateContext<ClientPlansStateModel>,
    { error }: ClientPlansActions.Failure
  ) {
    const errorStack = error as ErrorMessageModel;
    if (errorStack && errorStack.stack) {
      const waitTime: number | null = errorStack.stack?.error?.waitTime;
      if (waitTime) {
        const nowDate = new Date();
        ctx.dispatch(
          new StorageActions.Update({
            waitTime: {
              init: nowDate,
              finish: addMinutes(nowDate, waitTime),
            },
          })
        );

        ctx.dispatch(new Navigate(['main', 'dashboard-user']));
      }
    }
    ctx.patchState({
      error,
      loading: false,
    });
    return throwError(() => error);
  }

  /** Async */
  @Action(ClientPlansActions.List)
  public getAllSubscriptions(
    ctx: StateContext<ClientPlansStateModel>,
    { query }: ClientPlansActions.List
  ) {
    ctx.patchState({ loading: true });
    return this.clientPlansService.getAllSubscriptions(query).pipe(
      tap((payload) => {
        ctx.patchState({
          subscriptions: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }

  @Action(ClientPlansActions.ListUserSubs)
  public getUserSubscriptions(
    ctx: StateContext<ClientPlansStateModel>,
    { query }: ClientPlansActions.ListUserSubs
  ) {
    ctx.patchState({ loading: true });
    return this.clientPlansService.getUserSubscription(query).pipe(
      tap((payload) => {
        ctx.patchState({
          userSubs: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }

  @Action(ClientPlansActions.SubscribeNewUser)
  public createSubscription(
    ctx: StateContext<ClientPlansStateModel>,
    { body }: ClientPlansActions.SubscribeNewUser
  ) {
    ctx.patchState({ loading: true });
    return this.clientPlansService.createSubscription(body).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new AuthActions.Login({
            email: body.userData.email,
            password: body.userData.password,
          })
        )
      ),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }

  @Action(ClientPlansActions.SubscribeUserLogged)
  public createSubscriptionUserLogged(
    ctx: StateContext<ClientPlansStateModel>,
    { body }: ClientPlansActions.SubscribeUserLogged
  ) {
    ctx.patchState({ loading: true });
    return this.clientPlansService.createSubscriptionUserLogged(body).pipe(
      tap((payload) => {
        ctx.patchState({ loading: false, subsID: payload.id });
        if (this.waitTime()) {
          ctx.dispatch(new StorageActions.Update({ waitTime: null }));
        }
      }),
      mergeMap(() => ctx.dispatch(new ClientPlansActions.ListUserSubs(null))),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }

  @Action(ClientPlansActions.ChangeSubscription)
  public changeSubscriptionUserLogged(
    ctx: StateContext<ClientPlansStateModel>,
    { body, currentID }: ClientPlansActions.ChangeSubscription
  ) {
    ctx.patchState({ loading: true });
    return this.clientPlansService.changeSubscription(body, currentID).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => ctx.dispatch(new ClientPlansActions.ListUserSubs(null))),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }

  @Action(ClientPlansActions.CancelSubscription)
  public cancelSubscription(
    ctx: StateContext<ClientPlansStateModel>,
    { body, id }: ClientPlansActions.CancelSubscription
  ) {
    ctx.patchState({ loading: true });
    return this.clientPlansService.cancelSubs(body, id).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => ctx.dispatch(new ClientPlansActions.ListUserSubs(null))),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }

  @Action(ClientPlansActions.DaysRemaining)
  public getDaysRemaining(
    ctx: StateContext<ClientPlansStateModel>,
    { id }: ClientPlansActions.DaysRemaining
  ) {
    return this.clientPlansService.getDaysRemaining(id).pipe(
      tap((payload) => {
        ctx.patchState({ daysRemaining: payload.daysRemaining });
      }),
      catchError((error) => ctx.dispatch(new ClientPlansActions.Failure(error)))
    );
  }
}
