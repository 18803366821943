import { Injectable, inject } from '@angular/core';
import { BASE_URL_API, HttpService } from '@carwash-project/modules/core';
import { Observable } from 'rxjs';
import {
  CancelSubscriptionModel,
  ChangeSubscriptionModel,
  ClientPlanModel,
  CreateSubscriptionsModel,
  GetAllSubscriptionsModel,
  GetUserSubscriptionModel,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ClientPlansService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly httpService = inject(HttpService);
  private readonly pathClientPlan = `${this.baseApi}clientplan`;

  public getAllSubscriptions(
    query: GetAllSubscriptionsModel.Request | null
  ): Observable<GetAllSubscriptionsModel.Response> {
    return this.httpService.get(
      this.pathClientPlan,
      query,
      'Error al obtener el listado de suscripciones'
    );
  }

  public createSubscription(
    body: CreateSubscriptionsModel.Request
  ): Observable<CreateSubscriptionsModel.Response> {
    return this.httpService.post(
      this.pathClientPlan,
      body,
      'Error al crear la suscripción'
    );
  }

  public createSubscriptionUserLogged(
    body: CreateSubscriptionsModel.ReqUserLogged
  ): Observable<CreateSubscriptionsModel.ResUserLogged> {
    return this.httpService.post(
      this.pathClientPlan,
      body,
      'Error al crear la suscripción'
    );
  }

  public getUserSubscription(
    query: GetUserSubscriptionModel.Request | null
  ): Observable<GetUserSubscriptionModel.Response> {
    const path = `${this.pathClientPlan}/me`;
    return this.httpService.get(
      path,
      query,
      'Error al obtener el listado de suscripciones.'
    );
  }

  public changeSubscription(
    body: ChangeSubscriptionModel.Request,
    currentMembershipID: number | string
  ): Observable<CreateSubscriptionsModel.ResUserLogged> {
    const url = `${this.pathClientPlan}/${currentMembershipID}`;
    return this.httpService.patch(url, body, 'Error al cambiar el plan.');
  }

  public cancelSubs(
    body: CancelSubscriptionModel.Request,
    id: number | string
  ): Observable<CancelSubscriptionModel.Response> {
    const url = `${this.baseApi}cancell-clientplan/${id}`;
    return this.httpService.patch(
      url,
      body,
      'Error al dar de baja la membresía.'
    );
  }

  public getDaysRemaining(
    id: number | string
  ): Observable<ClientPlanModel.DaysRemaining> {
    const url = `${this.pathClientPlan}/days-remaining/${id}`;
    return this.httpService.get(
      url,
      null,
      'Error al Obtener los días restantes de la membresía.'
    );
  }
}
